import React, {Component} from 'react';
import GridItem from '../../../../vendor/components/Grid/GridItem';
import GridContainer from '../../../../vendor/components/Grid/GridContainer';
import {FormData, WithFormProp} from '../../../../core/components/form_data';
import CustomSelectFormInput from '../../../../core/components/form_data/custom_select_form_input';
import CustomFormInput from '../../../../core/components/form_data/custom_form_input';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  meetsMaxAgeRequirement,
  meetsMaxAgeRequirementWithAsOfDate,
  meetsMinAgeRequirement,
  meetsMinAgeRequirementWithAsOfDate,
  validateEmail,
} from '../../../../core/shared/shared_helpers';
import SnackbarContent from '../../../../vendor/components/Snackbar/SnackbarContent';
import DateTimeFormInput from '../../../../core/components/form_data/date_time_picker_form_input';
import {CountySelectFormData} from '../../../../core/components/region_select/county_select';
import {Waiver} from '../../../../enrollments/components/waivers/edit_waivers';

class Step1GeneralInfo extends Component<any, any> {
  sendState() {
    return this.state;
  }

  isValidated() {
    const {event, participant, registration, stateSettings} = this.props;
    const errors = [];
    // console.log("participant", participant);
    // console.log("registration", registration);
    // console.log("event", event);
    if (!participant.firstName) {
      errors.push({
        fieldKey: 'firstName',
        fieldTitle: 'Participant First Name',
        error: 'Participant First Name is required',
      });
    }
    if (!participant.lastName) {
      errors.push({
        fieldKey: 'lastName',
        fieldTitle: 'Participant Last Name',
        error: 'Participant Last Name is required',
      });
    }
    if (!participant.email) {
      errors.push({
        fieldKey: 'email',
        fieldTitle: 'Adult/Guardian Email',
        error: 'Adult/Guardian Email is required',
      });
    } else if (!validateEmail(participant.email)) {
      errors.push({
        fieldKey: 'email',
        fieldTitle: 'Email',
        error: 'Invalid Adult/Guardian Email',
      });
    }
    if (this.isBirthdateRequired() && !participant.birthday) {
      errors.push({
        fieldKey: 'birthday',
        fieldTitle: 'Birthday',
        error: 'Participant Birthday is required',
      });
    }
    //if (this.isBirthdateRequired() && ((event.minAge && (event.minAgeAsOf

    if (registration?.type === 'ClubMember' &&
        (
            (event.minAge && (event.minAgeAsOf
                    ? !meetsMinAgeRequirementWithAsOfDate(event.minAge, participant.birthday, event.minAgeAsOf)
                    : !meetsMinAgeRequirement(event.minAge, participant.birthday, stateSettings)
            ))

            || (event.maxAge && (event.maxAgeAsOf
                    ? !meetsMaxAgeRequirementWithAsOfDate(event.maxAge, participant.birthday, event.maxAgeAsOf)
                    : !meetsMaxAgeRequirement(event.maxAge, participant.birthday, stateSettings)
            ))
        )
    ) {
      errors.push({
        fieldKey: 'birthday',
        fieldTitle: 'Birthday',
        error: 'Birthday does not meet the age requirements',
      });
    }
    if (!participant.countyId) {
      errors.push({
        fieldKey: 'countyId',
        fieldTitle: 'County',
        error: 'County is required',
      });
    }
    if (!registration.type) {
      errors.push({
        fieldKey: 'type',
        fieldTitle: 'Participant Type',
        error: 'Participant Type is required',
      });
    }

    if (errors.length) {
      this.setState({errors});
      return false;
    }

    this.setState({errors: []});
    return true;
  }

  isBirthdateRequired() {
    const {event, registration, settings: {state: stateSettings}} = this.props,
        enrollmentWaivers = (stateSettings?.enrollmentSteps?.waivers?.clubMember || []) as Waiver[];
    return (registration?.type === 'ClubMember')
        && (event.participantWaivers || [])
            .filter((waiver: Waiver) => {
              if (waiver.minAge || waiver.maxAge) {
                return true;
              } else if (waiver.type === 'confirm') {
                const confirmWaiver = enrollmentWaivers.find(w => w.key === waiver.confirmWaiverKey);
                return confirmWaiver?.minAge || confirmWaiver?.maxAge;
              } else {
                return false;
              }
            }).length;
  }

  render() {
    const {event, participant, registration, stateId, settings: {state: stateSettings}, update} = this.props;
    const {errors = []} = this.state || {};
    const internalRegistrationLink = 'https://4h.zsuite.org/event-registrations/' + event.id;
    //console.log("INTERNAL REGISTRATION LINK", internalRegistrationLink);
    //console.log("EVENT PARTICIPANT REG FIELDS", event.participantRegFields.length);
    // console.log("EVENT", event);
    return <div>
      <h3 style={{textAlign: 'center'}}>General Participant Information</h3>
      <h6 style={{textAlign: 'center'}}>Have an account in ZSuite? Click <a href={internalRegistrationLink}
                                                                            target={"blank_"}><b><u><i>HERE</i></u></b></a> to
        log into your account and register in ZSuite</h6>

      <GridContainer>
        <FormData data={{participant, registration}}
                  onChange={({participant, registration}: any) => update(participant, registration)}>
          <WithFormProp prop="participant">
            <GridItem xs={12} md={6}>
              <CustomFormInput id="firstName" required
                               error={errors.filter((e: any) => e.fieldKey === 'firstName').length > 0}
                               labelText={event.id === 24195 ? "Participant First Name (Team Captain)" : "Participant First Name"}/>
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomFormInput id="lastName" required
                               error={errors.filter((e: any) => e.fieldKey === 'lastName').length > 0}
                               labelText={event.id === 24195 ? "Participant Last Name (Team Captain)" : "Participant Last Name"}/>
            </GridItem>
            <GridItem xs={12} md={6}>
              <CustomFormInput id="email" required
                               error={errors.filter((e: any) => e.fieldKey === 'email').length > 0}
                               labelText="Adult/Guardian Email"/>
            </GridItem>
          </WithFormProp>
          <WithFormProp prop="registration">
            <GridItem xs={12} md={6}>
              <CustomSelectFormInput id="type" required
                                     error={errors.filter((e: any) => e.fieldKey === 'type').length > 0}
                                     labelText="Participant Type"
                                     options={[
                                       ...(
                                           //IF THE REMAINING SPACE IS NOT DEFINED OR THERE ARE NO SPACES LEFT DO NOT SHOW THE CLUB MEMBER (YOUTH) DROPDOWN OPTION
                                           (
                                               event.remainingClubMemberSpace !== undefined &&
                                               event.remainingClubMemberSpace <= 0
                                           )

                                               //IF THERE ARE NO PARTICIPANT REGISTRATION FIELDS SET UP, DO NOT SHOW THE CLUB MEMBER DROPDOWN OPTION
                                               //|| (event.participantRegFields.length <= 0 && event.participantWaivers.length <= 0)
                                               ? []
                                               : [
                                                 {
                                                   id: 'ClubMember',
                                                   //label: `${stateSettings.clubMemberLabel}${event.remainingClubMemberSpace !==
                                                   label: `Youth ${event.remainingClubMemberSpace !==
                                                   undefined ? `- ${event.remainingClubMemberSpace} space(s) left` : ''}`,
                                                 }]),


                                       ...(
                                           (
                                               //DO NOT SHOW THE VOLUNTEER DROPDOWN OPTION IF VOLUNTEER REGISTRATION IS NOT ENABLED
                                               !event.allowVolunteerReg

                                               //IF THE REMAINING SPACE IS NOT DEFINED OR THERE ARE NO SPACES LEFT DO NOT SHOW THE VOLUNTEER (ADULT) DROPDOWN OPTION
                                               || (event.remainingVolunteerSpace !== undefined && event.remainingVolunteerSpace <= 0)
                                           )
                                               ? [] : [
                                                 {
                                                   id: 'Volunteer',
                                                   //label: `${stateSettings.volunteerLabel}${event.remainingVolunteerSpace !==
                                                   label: `Adult ${event.remainingVolunteerSpace !==
                                                   undefined ? `- ${event.remainingVolunteerSpace} space(s) left` : ''}`,
                                                 }]),
                                     ]}/>
            </GridItem>
          </WithFormProp>
          <WithFormProp prop="participant">
            <GridItem xs={12} md={6}>
              <CountySelectFormData id="countyId"
                                    stateFilter={
                                      // Filter example state in production
                                      process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'
                                          ? ((s: { name: string; }) => {

                                            //THIS CODE CHECKS IF THE STATE IS A DEMO STATE OR NOT AND IF IT IS A DEMO
                                            //STATE THEN SHOW THE LIST OF OTHER DEMO STATES OTHERWISE HIDE THEM.
                                            //THIS WAY DEMO STATES CAN STILL TEST MAKING EVENTS
                                            return (event?.stateId || '').toLowerCase().endsWith('DEMO') || (event?.stateId || '').toLowerCase().startsWith('ex') ?
                                                s && s.name
                                                : s && s.name
                                                && !s.name.toLowerCase().startsWith('example')
                                                && !s.name.toLowerCase().endsWith('demo')
                                                && !s.name.toLowerCase().endsWith('(archived)')
                                                //&& !s.name.toLowerCase().startsWith('californi')
                                                && !s.name.toLowerCase().startsWith('demo')
                                                && !s.name.toLowerCase().endsWith('- extension')
                                          })
                                          : undefined
                                    }
                                    stateId={stateId}
                                    showStateSelect
                                    label="County"/>
            </GridItem>
            <GridItem xs={12} md={6}>
              {/*{this.isBirthdateRequired()*/}
              {/*    ? <DateTimeFormInput dateFormat id="birthday" label="Participant Birthday"/>*/}
              {/*    : null as any}*/}
              {
                  registration.type === 'ClubMember' &&
                  <DateTimeFormInput dateFormat id="birthday" label="Participant Birthday"/>
              }
            </GridItem>
          </WithFormProp>
        </FormData>
      </GridContainer>

      {errors?.length ? <SnackbarContent
          message={<div>
            Please fix the following errors before continuing:
            <ul>
              {errors.map((e: any, i: number) => <li key={i}>{e.error}</li>)}
            </ul>
          </div>}
          onClose={() => this.setState({errors: []})}
          close
          color="danger"
      /> : ''}
    </div>;
  }
}

export default withStyles({} as any)(Step1GeneralInfo);